<template>
    <main>
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                  <div class="cover" >

                  </div>
                </div>
                <div class="col-lg-12">
                    <div class="container box-title">
                        <div class="row">
                            <div class="col-lg-12">
                                <h1>Meme Cake Offical NFT offering</h1>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-lg-8">
                                <div class="presale-box">
                                    <p>
                                       The Cake Club is a collection of 7777 RICH NFTs unique digital collectible living on the Internet computer.
                                        Each Dick RICH is programmatically generated from over 150 characteristics.
                                    </p>
                                    <p>
                                      Your RICH NFT doubles as your meme club membership card, future airdrops, and grants access to members-only benefits.
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-4 buy-now">
                                <a target="_blank" href="https://entrepot.app/marketplace/memecake" class="btn">TRADE ON ENTREPOT</a>
                                 <p class="rw-pgm">
                              Buy 4 or more RICH NFT to enter into the Meme Cake NFT staking program.
                                </p>
                                <p>
                                    Anyone who owns RICH NFT will be eligible for our upcoming Balls Airdrop at 1:1 ratio.
                                </p>
                               
                            </div>
                        </div>
                        <div id="packages" class="row mt-5 package-container justify-content-center">
                            <div class="col-lg-12 mb-3">
                                <h1 style="color:#ff0019">TRADE ON <a target="_blank" href="https://entrepot.app/marketplace/memecake"> ENTREPOT</a> </h1>
                                <br>
                                
                                <p style="text-align: center;font-size:34px;font-weight:bold">                               
                                   To know more please join our <a href="https://discord.gg/WFkRssvNtW" target="_blank" style="color: var(--primary-color);">Discord server.</a>
                                </p>
                            </div>
                           

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>    
</template>
<style>
.cover {
    width: 100%;
    height: 38vh;
    background: url('https://d15bmhsw4m27if.cloudfront.net/public/banner-1.jpg');
    background-size: cover;
    background-position: center center;
}
.buy-now{
    position: relative;
    text-align: center;
    padding-top:34px;
}

.box-title{
    margin-top: 40px;
}
.box-title h1{
    text-align: center;
    color: var(--primary-color);
    font-size: 38px;
}
.presale-box {
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 5px 25px 0 rgb(123 123 123 / 15%);
    margin-bottom: 20px;
    padding: 15px !important;
    font-size: 18px;
    line-height: 1.5;
    
}
.buy-now .btn{
    width: 80%;
    background:  var(--primary-color);
    color: var(--dark-color);
}
.buy-now p{
    font-size: 16px;
    line-height: 1.5;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}
.package{
    width: 100%;
    display: inline-block;
    padding: 30px;
    border: solid 1px rgba(0, 0, 0, .2);
    box-shadow: 1px 1px 8px 1px rgb(0 0 0 / 30%);
    -webkit-box-shadow: 1px 1px 8px 1px rgb(0 0 0 / 30%);
    -moz-box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background: var(--white-clor) ;
    text-align: center;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    position: relative;
    margin-bottom: 30px;
}
.package h2{
    color:  var(--primary-color);
    font-size: 32px;
}
.package p{
    color: var(--dark-color);
    font-weight: bold;
}
.package .icon{
    width: 100px;
    height: 100px;
    display: block;
    margin: 10px auto;
    border-radius: 50%;
    background-position: center !important;
}
.package:hover{
    border: solid 1px var(--primary-color);
    box-shadow: 1px 1px 8px 1px rgb(249 185 60 / 30%);
    -webkit-box-shadow: 1px 1px 8px 1px rgb(249 185 60 / 30%);
    -moz-box-shadow: 1px 1px 8px 1px rgb(249 185 60 / 30%);
}
.rw-pgm {
    font-weight: bolder;
    background-color: #000;
    box-shadow: 0 5px 25px 0 rgb(123 123 123 / 15%);
    margin-bottom: 20px;
    padding: 14px !important;
    color: #f9b93c;
    border-radius: 4px;
}
@media (max-width: 480px) {
    .buy-now .btn,.buy-now p{
        width: 100%;
    }
    
}
</style>
<script>
import noty from 'noty'
import axios from '@/plugins/axios'

export default {
    name: "Launchpad",
     mounted() {
       
     },
    data () {
        return {
            packages:[
               
                   
            ],
           
        }
    },
    methods: {
        InitiateTrx:async function (pid) {
            return false
            if (!window.ic) new noty({text: 'You need to integrate your Plug Wallet to make a transaction' , type:"error", layout:"bottomRight" , timeout:1000}).show();
            var result = await window.ic.plug.isConnected();
             if(result){
                  const params = { 
                      to: this.walletAddress,
                      amount: this.packages[pid].icp,
                      memo: 'dickpack '+this.packages[pid].nft,
                };
                const result = await window.ic.plug.requestTransfer(params).catch(error=>{
                    new noty({text: 'Purchase Failed ' , type:"error", layout:"bottomRight" , timeout:1000}).show();
                });
                if(result){
                    axios.post('/api/user/orderstat', { package: this.packages[pid].nft ,trxId: result.height}).then(res=>{ })
                    new noty({text: 'NFT Purchase Successfull. We Will send you confirmation mail soon.' , type:"success", layout:"bottomRight" , timeout:3000}).show();
                }

             }else{
                 var self= this
                 new noty(
                     {text: 'Wallet not connected, Connect your Plug wallet and Try again' , type:"error", layout:"bottomRight" , timeout:1000,
                      callbacks: {
                          afterShow: function() {
                               self.$router.push({ name: 'wallet-connect' }) 
                          },
                      }
                     }
                ).show();
             }
        },
        checkPlugConnected:async function(){
              
        },
    }
    
}
</script>
